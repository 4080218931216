<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Создание реквизита
    </h4>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="6">
          <b-form-group label="Название">
            <b-form-input
              v-model.trim="$v.form.name.$model"
              type="text"
              autocomplete="false"
              autofocus
              :state="chkState('name')"
              aria-describedby="contactFormName"
            />
            <b-form-invalid-feedback id="contactFormName">
              <span v-if="!$v.form.name.required">Поле обязательно для заполнения</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Роль">
            <b-form-input
              v-model="form.role"
              type="text"
              autocomplete="false"
              autofocus
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="ФИО">
            <b-form-input
              v-model.trim="$v.form.fullname.$model"
              type="text"
              :state="chkState('fullname')"
              aria-describedby="contactFormFullname"
            />
            <b-form-invalid-feedback id="contactFormFullname">
              <span v-if="!$v.form.fullname.required">Поле обязательно для заполнения</span>
              <span v-if="!$v.form.fullname.сyrillic">Поле принимает только символы кириллицы</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Должность">
            <b-form-input
              v-model="form.position"
              type="text"
              autocomplete="false"
              autofocus
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Email">
            <b-form-input
              v-model.trim="$v.form.email.$model"
              name="email"
              autocomplete="false"
              autofocus
              :state="chkState('email')"
              aria-describedby="contactFormEmail"
            />
            <b-form-invalid-feedback id="contactFormEmail">
              <span v-if="!$v.form.email.required">Поле обязательно для заполнения</span>
              <span v-if="!$v.form.email.email">Поле принимает только email адрес</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Телефон">
            <b-form-input
              id="phone1"
              v-model="form.phone"
              v-mask="'+7 (999) 999-99-99'"
              type="text"
              autocomplete="false"
              autofocus
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Мобильный телефон">
            <b-form-input
              id="phone2"
              v-model="form.mobilePhone"
              v-mask="'+7 (999) 999-99-99'"
              type="text"
              autocomplete="false"
              autofocus
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="ICQ">
            <b-form-input
              v-model="form.icq"
              type="text"
              autocomplete="false"
              autofocus
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Skype">
            <b-form-input
              v-model="form.skype"
              type="text"
              autocomplete="false"
              autofocus
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-checkbox
              v-model="form.isDefault"
              autocomplete="false"
              autofocus
            >
              Реквизит по умолчанию
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="loading"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {validation} from '../../components/mixins/validation';
import {helpers} from 'vuelidate/lib/validators';
const сyrillic = helpers.regex('alpha', /[а-я]/);
import {required, email} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {contactRequisitesCreate, contactRequisitesUpdate, contactRequisitesRead} from '../../services/api';

export default {
  name: 'ContactForm',
  mixins: [validationMixin, validation, notifications],
  props: ['contactId'],
  data() {
    return {
      loading: false,
      form: {
        name: '',
        role: '',
        fullname: '',
        position: '',
        email: '',
        phone: '',
        mobilePhone: '',
        icq: '',
        skype: '',
        isDefault: false,
        customer: {
          id: null,
        },
      },
      contactRequisite: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      fullname: {
        сyrillic,
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
  mounted() {
    this.form.customer.id = this.$store.state.user.ownerId;
    if (this.contactId) {
      this.contactRequisitesRead();
    }
  },
  methods: {
    async contactRequisitesRead() {
      this.loading = true;
      const response = await contactRequisitesRead(this.contactId);
      if (response && response.status === 200) {
        this.form = Object.assign({}, response.data);
      }
      this.loading = false;
    },
    onSubmit() {
      if (this.validate()) {
        this.send();
      }
    },
    async send() {
      this.loading = true;
      if (this.contactId) {
        const response = await contactRequisitesUpdate(this.contactId, this.form);
        if (response && response.status === 200) {
          this.showSuccess('Контактные реквизиты обновлены');
          this.$router.push({path: '/contact-requisites'});
        }
      } else {
        const response = await contactRequisitesCreate(this.form);
        if (response && response.status === 201) {
          this.showSuccess('Контактные реквизиты созданы');
          this.$router.push({path: '/contact-requisites'});
        }
      }
      this.loading = false;
    },
  },
};
</script>

